<template>
  <div class="container">
    <div class="content-container">
      <div class="t-temp-wraper">
        <div class="feed-wraper">
          <div
            class="t-templates"
            v-show="int.visibility"
            v-for="(int, idx) of pages"
            :key="idx"
          >
            <div class="t-temp-container" @click="addToPreview(int, $event)">
              <div class="hover-wraper-content">
                <img alt="img" :src="int.img" class="t-temp-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Survey from "@/util/survey/survey";
import rating from "@/services/catalog/RatingsService";

export default {
  name: "pages",
  computed: {
    ...mapGetters({
      device: "workSpace/device",
      pages: "feed/pages",
      survey: "workSpace/survey"
    })
  },
  methods: {
    async addToPreview(item) {
      const pageContent = await Survey.parsePage(item);
      await Survey.renderPage(pageContent);
      rating.incrementRating(item.id, "page");
      this.survey.fire("canvas:changes");
      if (this.device === "mobile")
        this.$store.commit("workSpace/updateVisibleFeedPanel", false);
    }
  }
};
</script>

<style scoped>
.t-temp-img {
  object-fit: contain;
  height: 108px;
}
.t-temp-wraper,
.categories {
  position: relative;
  overflow-x: hidden;
}
.content-container {
  position: relative;
}
.preloader {
  height: calc(100vh - 245px);
  z-index: 5;
}
.main-preloaeder {
  z-index: 10;
}
.close-tab {
  position: relative;
  height: 20px;
}
.close-tab span {
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  top: 4px;
  right: 10px;
  cursor: pointer;
}
.t-templates {
  margin: 0 5px 0 5px;
}
.t-temp-container {
  margin-bottom: 10px;
  border-radius: 7px;
  width: auto;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}
.t-temp-wraper {
  height: auto;
  margin: 0;
  padding: 0 15px;
  box-sizing: border-box;
}
.feed-wraper {
  margin-bottom: 0;
}
@media screen and (max-width: 990px) {
  .t-temp-wraper {
    margin: 0 -5px;
    padding: 5px;
    overflow: visible;
    height: auto;
  }
}
</style>
